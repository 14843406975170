@font-face {
  font-family: "Paragraph";   /*Can be any text*/
  src: local("Paragraph"),
    url("./fonts/Chakra/ChakraPetch-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Header";   /*Can be any text*/
  src: local("Header"),
    url("./fonts/Geom Graphic W03 Regular.ttf") format("truetype");
}